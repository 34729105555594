import _isobject from "isobject";
import _isDescriptor from "is-descriptor";
var exports = {};
var isobject = _isobject;
var isDescriptor = _isDescriptor;
var define = typeof Reflect !== "undefined" && Reflect.defineProperty ? Reflect.defineProperty : Object.defineProperty;

exports = function defineProperty(obj, key, val) {
  if (!isobject(obj) && typeof obj !== "function" && !Array.isArray(obj)) {
    throw new TypeError("expected an object, function, or array");
  }

  if (typeof key !== "string") {
    throw new TypeError("expected \"key\" to be a string");
  }

  if (isDescriptor(val)) {
    define(obj, key, val);
    return obj;
  }

  define(obj, key, {
    configurable: true,
    enumerable: false,
    writable: true,
    value: val
  });
  return obj;
};

export default exports;